export const USER_PERSONALIZED_QUESTIONS = [
  'your-motivation',
  'age',
  'when-to-start',
  'knowledge-level',
  'ideal-schedule',
];

export const DIRECTION_QUESTION = 'preferred-direction';
export const COURSE_QUESTION = 'preferred-course';

interface Mapping {
  [key: string]: { [key: string]: string };
}

export const PERSONALIZED_ANSWERS_MAP: Mapping = {
  'your-motivation': {
    'Щоб працювати онлайн': 'Працювати онлайн',
    'Підвищити дохід': 'Підвищити дохід',
    'Для саморозвитку': 'Для саморозвитку',
    'Зарплата в доларах': 'Зарплата в доларах',
    'Вже працюю в ІТ, хочу покращити навички': 'Покращити навички',
    Other: 'Підвищити дохід',
  },
  age: {
    '16-24': '16-24',
    '25-34': '25-34',
    '35-50': '35-50',
    '15 і менше': '15 і менше',
    '50 і більше': '50 і більше',
  },
  'when-to-start': {
    'Прямо зараз': 'Зараз',
    'Ще не знаю': 'Ще не вирішив',
    'Через кілька тижнів': 'Наступного тижня',
  },
  'knowledge-level': {
    'Повний нуль, але з мотивацією': 'Початківець',
    'Проходив курси, читав книжки': 'Проходив курси',
    'Маю фахову освіту': 'Маю освіту',
    'Працюю в цій професії': 'Працюю в професії',
  },
  'ideal-schedule': {
    'Онлайн, вільний графік з постійною підтримкою ментора': 'Онлайн',
    'Важко сказати': 'Важко сказати',
    'Онлайн, заняття з 9:00 до 18:00 в будні дні': '9 - 18:00, будні',
    'Офлайн, в чітко визначені години': 'Офлайн',
  },
  'preferred-course': {
    Дизайн: 'UI/UX дизайнер',
    'Тестування (QA)': 'QA розробник',
    Маркетинг: 'Digital-маркетолог',
    'Дата аналітика': 'Data-аналітик',
    Рекрутмент: 'Рекрутер',
    Python: 'Python розробник',
    Fullstack: 'Fullstack розробник',
    Java: 'Java розробник',
    'Front-end': 'розробник',
    DevOps: 'DevOps інженер',
    'Ще не знаю': 'IT професія мрії',
  },
};

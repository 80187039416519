import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';

export const SESSION_STORAGE_KEYS = {
  enteredUserInfo: (
    field: EnteredUserInfoField,
  ) => `entered_user_${field}`,
  careerTestUUID: 'career_test_uuid',
  supportTestsButtonShown: 'support_tests_button_shown',
  codeEditorLargeFileWarning: (
    contextId: string,
    filePath: string,
  ) => `CODE_EDITOR_LARGE_FILE_WARNING_${contextId}_${filePath}`,
  eventsOwnFilter: 'events_own_filter',
  initTurnOnTimeTrackerMessageWasShown: 'init_turn_on_time_tracker_message_was_shown',
  initNotificationMessageWasShown: 'init_notification_message_was_shown',
  isEnteredUserTimezone: 'is_entered_user_time_zone',
  wasEnglishSubscriptionEndingBannerShown: 'was_english_subscription_ending_banner_shown',
  userSurveySubmitted: (event: string) => `user_survey_submitted_${event}`,
  currentFreeTrialPaywallDisplays: 'current_free_trial_paywall_displays',
};

import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';
import { initLocalStorage } from '@/lib/browserStorage';
import {
  COURSE_QUESTION,
  DIRECTION_QUESTION,
  PERSONALIZED_ANSWERS_MAP,
  USER_PERSONALIZED_QUESTIONS,
} from '@/components/user-survey/userSurvey.constants';
import { TypeformAnswer } from '@/controllers/graphql/generated';

export const enteredUserEmailLocalStorage = initLocalStorage({
  key: LOCAL_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Email),
  initialValue: '',
});

export const enteredUserPhoneLocalStorage = initLocalStorage({
  key: LOCAL_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Phone),
  initialValue: '',
});

interface Props {
  email: string;
  phone: string;
}

export const saveUserInfoToLocalStorage = (props: Props) => {
  const {
    email,
    phone,
  } = props;

  enteredUserEmailLocalStorage.writeToStorage(email);
  enteredUserPhoneLocalStorage.writeToStorage(phone);
};

export const lastTypeformIdLocalStorage = initLocalStorage({
  key: LOCAL_STORAGE_KEYS.lastTypeformId,
  initialValue: '',
});

export const lastTypeformResponseIdLocalStorage = initLocalStorage({
  key: LOCAL_STORAGE_KEYS.lastTypeformResponseId,
  initialValue: '',
});

export const lastTypeformAnswersLocalStorage = initLocalStorage({
  key: LOCAL_STORAGE_KEYS.lastTypeformAnswers,
  initialValue: {},
});

const getTypeformMappedValue = (
  question: string,
  value: string,
): string => {
  const questionMapping = PERSONALIZED_ANSWERS_MAP[question];
  const mappedValue = questionMapping?.[value];

  if (!questionMapping || !mappedValue) {
    return value;
  }

  return mappedValue;
};

interface PreparedQuestion {
  questionId: string;
  value: string;
}

const prepareTypeformAnswers = (
  answers: TypeformAnswer[],
): PreparedQuestion[] => {
  const filteredAnswers = answers.filter((answer) => (
    USER_PERSONALIZED_QUESTIONS.includes(answer.questionId)
  ));

  const preparedAnswers: PreparedQuestion[] = filteredAnswers.map((answer) => ({
    questionId: answer.questionId,
    value: getTypeformMappedValue(answer.questionId, answer.value || ''),
  }));

  const selectedDirection = answers.find(
    ({ questionId }) => questionId === DIRECTION_QUESTION,
  );

  if (selectedDirection) {
    if (selectedDirection.value === 'Розробка') {
      const selectedCourse = answers.find(
        ({ questionId }) => questionId === COURSE_QUESTION,
      );

      const course = {
        questionId: COURSE_QUESTION,
        value: getTypeformMappedValue(COURSE_QUESTION, selectedCourse?.value || ''),
      };

      preparedAnswers.push(course);
    } else {
      const course = {
        questionId: COURSE_QUESTION,
        value: getTypeformMappedValue(COURSE_QUESTION, selectedDirection?.value || ''),
      };

      preparedAnswers.push(course);
    }
  }

  return preparedAnswers;
};

interface AnswersObject {
  [key: string]: string;
}

export const getTypeformAnswersObject = (
  answers: TypeformAnswer[],
): AnswersObject => {
  const preparedAnswers = prepareTypeformAnswers(answers);

  return preparedAnswers.reduce((acc, { questionId, value }) => {
    const answer = getTypeformMappedValue(questionId, value || '');

    return {
      ...acc,
      [questionId]: answer,
    };
  }, {});
};

export const getTypeformAnswersQuery = (
  answers: TypeformAnswer[],
): string => {
  const preparedAnswers = prepareTypeformAnswers(answers);

  return preparedAnswers.map(({ questionId, value }) => {
    const answer = getTypeformMappedValue(questionId, value || '');

    return `${questionId}=${answer}`;
  }).join('&');
};
